import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { apiWithAuth, apiWithoutAuth, BIND_USER_API } from '../api';
import './css/UserPanel.css'; // 假設你將CSS樣式保存為UserPanel.css文件

const UserPanel = () => {
  const { inviteCode } = useParams();
  const [streetNumber, setStreetNumber] = useState('');
  const [houseNumber, setHouseNumber] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    if (!streetNumber || !houseNumber) {
      setError('Both street number and house number are required.');
      return;
    }
    
    const packageAddress = `${streetNumber}巷${houseNumber}號`;

    if (!/^\d{1,3}巷\d+號$/.test(packageAddress)) {
      setError('Invalid address format. Please use the format: "xx巷xx號"');
      return;
    }
    
    setError('');
    try {
      const response = await apiWithoutAuth.post(BIND_USER_API, { inviteCode, packageAddress });

      if (response.data && response.data.authUrl) {
        window.location.href = response.data.authUrl;
      } else {
        console.error('No authorization URL returned from the server');
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        console.error('Error binding user:', error);
        setError('An error occurred while binding the user.');
      }
    }
  };

  return (
    <div className="user-panel">
      <h1>User Panel</h1>
      <div>
        <label>Street Number (e.g., 28):</label>
        <input
          type="number"
          value={streetNumber}
          onChange={(e) => setStreetNumber(e.target.value)}
          placeholder="Street Number"
        />
      </div>
      <div>
        <label>House Number (e.g., 65):</label>
        <input
          type="number"
          value={houseNumber}
          onChange={(e) => setHouseNumber(e.target.value)}
          placeholder="House Number"
        />
      </div>
      <button onClick={handleSubmit}>Submit and Authenticate with LINE Notify</button>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default UserPanel;

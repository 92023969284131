import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { apiWithAuth, apiWithoutAuth, LOGIN_API } from '../api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(localStorage.getItem('token') || '');
  const [isAuthenticated, setIsAuthenticated] = useState(!!authToken);
  const navigate = useNavigate();

  useEffect(() => {
    setIsAuthenticated(!!authToken);
  }, [authToken]);

  const login = async (username, password) => {
    try {
      const response = await apiWithoutAuth.post(LOGIN_API, { username, password });
      const token = response.data.token;
      localStorage.setItem('token', token);
      setAuthToken(token);
      setIsAuthenticated(true);
      navigate('/'); // 確保在狀態更新後再導航
    } catch (error) {
      console.error('Login error:', error);
      throw new Error('Invalid username or password');
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setAuthToken('');
    setIsAuthenticated(false);
    navigate('/login'); // 確保在狀態更新後再導航
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

import React,{ useState, useEffect } from 'react';
import { useNavigate, HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import { AuthProvider, useAuth } from './context/AuthContext';
import AdminPanel from './components/AdminPanel';
import UserPanel from './components/UserPanel';
import Login from './components/Login';

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <AdminPanel />
              </ProtectedRoute>
            }
          />
        </Routes>
      </AuthProvider>
      <Routes>
        <Route path="/user/:inviteCode" element={<UserPanel />} />
      </Routes>
    </Router>
  );
};

// const ProtectedRoute = ({ children }) => {
//   const { isAuthenticated } = useAuth();
//   console.log('ProtectedRoute isAuthenticated:', isAuthenticated); // 確認狀態
//   return isAuthenticated ? children : <Navigate to="/login" />;
// };

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    }
  }, [isAuthenticated, navigate]);

  return isAuthenticated ? children : null;
};
export default App;

import axios from 'axios';
// import { useNavigate } from 'react-router-dom';

const API_BASE_URL = 'https://estate-api.chang-cat.com/api';

export const LOGIN_API = '/login';
export const GENERATE_INVITE_API = '/generate-invite';
export const BIND_USER_API = 'bind-user';
export const LIST_BINDINGS_API = '/bindings';
export const UNBINDING_API = '/unbind-notify';
export const PUSH_MESSAGE_API = '/send-notification';

const api = axios.create({
  baseURL: API_BASE_URL,
});

// 不需要身份驗證的 API 請求
export const apiWithoutAuth = api;

// 需要身份驗證的 API 請求
export const apiWithAuth = (navigate) => {
  const token = localStorage.getItem('token');
//   const navigate = useNavigate();

  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete api.defaults.headers.common['Authorization'];
  }

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 403) {
        localStorage.removeItem('token');
        navigate('/login');
      }
      return Promise.reject(error);
    }
  );

  return api;
};

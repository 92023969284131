import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { apiWithAuth, apiWithoutAuth, GENERATE_INVITE_API, LIST_BINDINGS_API, UNBINDING_API, PUSH_MESSAGE_API } from '../api';
import { useAuth } from '../context/AuthContext';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import './css/AdminPanel.css';

const AdminPanel = () => {
  const [inviteCode, setInviteCode] = useState('');
  const [bindings, setBindings] = useState([]);
  const [expandedInviteCode, setExpandedInviteCode] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [customMessage, setCustomMessage] = useState('');
  const [sendInviteUrlBtn, setSendInviteUrlBtn] = useState(true);
  const [isAddressValid, setIsAddressValid] = useState(true); // 新增的狀態
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate();
  const { logout } = useAuth();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
    } else {
      fetchBindings(token);
    }
  }, [navigate]);

  const fetchBindings = async (token) => {
    try {
      const response = await apiWithAuth(navigate).get(LIST_BINDINGS_API);
      setBindings(response.data.bindings);
    } catch (error) {
      console.error('Error fetching bindings:', error);
    }
  };

  const handleAddressChange = (e) => {
    const address = e.target.value;
    setSelectedAddress(address);
    setIsAddressValid(bindings.some(binding => binding.package_address === address));
  };

  const generateInvite = async () => {
    setSendInviteUrlBtn(false)
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(emailAddress)) {
      setSendInviteUrlBtn(true)
      alert('請輸入有效的電子郵件地址');
      return;
    }
    try {
      const token = localStorage.getItem('token');
      const response = await apiWithAuth(navigate).post(GENERATE_INVITE_API, { email: emailAddress });
      setInviteCode(response.data.inviteCode);
      setEmailAddress("");
      setSendInviteUrlBtn(true);
    } catch (error) {
      console.error('Error generating invite:', error);
      setEmailAddress("");
      setSendInviteUrlBtn(true);
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const toggleExpand = (inviteCode) => {
    setExpandedInviteCode(prev => (prev === inviteCode ? null : inviteCode));
  };

  const handleDelete = async (packageAddress) => {
    try {
      const token = localStorage.getItem('token');
      await apiWithAuth(navigate).get(UNBINDING_API, {
        params: { address: packageAddress }
      });
      setBindings(prev => prev.filter(binding => binding.package_address !== packageAddress));
    } catch (error) {
      console.error('Error unbinding and deleting binding:', error);
    }
  };

  const pushMessage = async () => {
    setIsLoading(true)
    try {
      const token = localStorage.getItem('token');
      const response = await apiWithAuth(navigate).post(PUSH_MESSAGE_API, {
        packageAddress: selectedAddress,
        message: customMessage,
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.data.success) {
        console.log('Notification sent successfully');
      } else {
        console.error('Failed to send notification:', response.data.message);
      }
      setCustomMessage('');
    } catch (error) {
      setCustomMessage('');
      console.error('Error sending notification:', error);
    }
    setIsLoading(false)
  };

  return (
    <div className="admin-panel-container">
      <div className="tab-list-container">
        <Tabs>
          <TabList>
            <Tab>新增綁定邀請連結</Tab>
            <Tab>綁定列表</Tab>
            <Tab>發送訊息</Tab>
            <Tab onClick={handleLogout}>登出</Tab>
          </TabList>

          <TabPanel>
            <h1 className="admin-panel-title">新增綁定邀請連結</h1>
            <label htmlFor="address-select">輸入電子信箱:</label>
            <input 
              type="text" 
              placeholder="Email" 
              value={emailAddress} 
              onChange={(e) => setEmailAddress(e.target.value)}
            />
            <button 
              className="admin-panel-button" 
              onClick={generateInvite}
              disabled={!sendInviteUrlBtn}
            >
              生成邀請連結
            </button>
            {inviteCode && (
              <p className="invite-url">
                邀請連結: <a href={`/#/user/${inviteCode}`}>{`/user/${inviteCode}`}</a>
              </p>
            )}
          </TabPanel>

          <TabPanel>
            <h2 className="bindings-title">綁定列表</h2>
            <ul className="bindings-list">
              {bindings.map((binding) => (
                <li 
                  key={binding.invite_code} 
                  className="binding-item"
                  onClick={() => toggleExpand(binding.invite_code)}
                >
                  <div>
                    <p><strong>Package Address:</strong> {binding.package_address}</p>
                  </div>
                  {expandedInviteCode === binding.invite_code && (
                    <div className="binding-details">
                      <p><strong>LINE Token:</strong> {binding.line_token}</p>
                      <button
                        className="binding-delete-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDelete(binding.package_address);
                        }}
                      >
                        解除綁定
                      </button>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </TabPanel>

          <TabPanel>
            <h1 className="admin-panel-title">發送訊息</h1>
            <div className="push-message-form">
              <label htmlFor="address-select">選擇或輸入地址:</label>
              <select 
                id="address-select" 
                value={selectedAddress} 
                onChange={handleAddressChange}
              >
                <option value="">選擇地址</option>
                {bindings.map((binding) => (
                  <option key={binding.package_address} value={binding.package_address}>
                    {binding.package_address}
                  </option>
                ))}
              </select>
              <input 
                type="text" 
                placeholder="或直接輸入地址" 
                value={selectedAddress} 
                onChange={handleAddressChange}
                style={{ borderColor: isAddressValid ? '' : 'red' }} // 動態設定邊框顏色
              />
              <textarea 
                placeholder="輸入自定義訊息" 
                value={customMessage} 
                onChange={(e) => setCustomMessage(e.target.value)}
              ></textarea>
              <button 
                className="push-message-button" 
                onClick={pushMessage}
                disabled={!isAddressValid || !selectedAddress || isLoading || customMessage==''} // 當地址無效或未選擇時禁用按鈕
              >
                發送訊息
              </button>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default AdminPanel;
